export const UPDATE_HISTORY_ENTITY_SELLING_PRICE = 'sellingPrice'
export const UPDATE_HISTORY_ENTITY_PURCHASE_PRICE = 'purchasePrice'
export const UPDATE_HISTORY_ENTITY_STOCK = 'stock'
export const UPDATE_HISTORY_ENTITY_DELIVERY_LEAD_TIME = 'deliveryLeadTime'
export const UPDATE_HISTORY_ENTITY_STATUS = 'status'
export const UPDATE_HISTORY_ENTITY_PACKAGE_TYPE = 'packageType'
export const UPDATE_HISTORY_ENTITY_SA_COMMISSION_PACKAGE_TYPE =
  'saCommissionPackageType'
export const UPDATE_HISTORY_ENTITY_SA_COMMISSION = 'saCommission'
export const UPDATE_HISTORY_ENTITY_SA_COMMISSION_UNIT = 'saCommissionUnit'

export const IS_FORMAT_MONEY = [
  UPDATE_HISTORY_ENTITY_SELLING_PRICE,
  UPDATE_HISTORY_ENTITY_PURCHASE_PRICE,
  UPDATE_HISTORY_ENTITY_SA_COMMISSION,
]

export const IS_LAST_7_DAYS = 'isLast7Days'
export const IS_LAST_30_DAYS = 'isLast30Days'
export const IS_DATE_RANGE = 'isDateRange'

export const PRODUCT_PRE_ORDER_FILTER = [IS_LAST_7_DAYS, IS_LAST_30_DAYS]

export const DATE_SELECT = {
  [IS_LAST_7_DAYS]: 'in last 7 days',
  [IS_LAST_30_DAYS]: 'in last 30 days',
}

export const UPDATE_HISTORY_ENTITY_STOCK_OR_PACKAGE_TYPE = [
  UPDATE_HISTORY_ENTITY_PACKAGE_TYPE,
  UPDATE_HISTORY_ENTITY_STOCK,
]

export const PROCESS_TYPE_RETAIL = 'retail'
export const PROCESS_TYPE_TRADING = 'trading'
export const PROCESS_TYPE_PRE_ORDER = 'pre_order'
