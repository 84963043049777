/* eslint-disable @typescript-eslint/no-explicit-any */
import { omit, pipe, set } from 'lodash/fp'
import { useRouter } from 'next/router'
import { ParsedUrlQuery } from 'querystring'
import { useMemo } from 'react'

export const DEFAULT_PAGE = 1
export const DEFAULT_PAGE_SIZE = 20
export const DEFAULT_PAGE_SIZE_50 = 50
export const DEFAULT_MAX_PAGE_SIZE = 100
export const ALLOWED_PAGE_SIZES = [10, 20, 50, 100]
export const DEFAULT_EXPORT_PAGE_SIZE = 10000
export const DEFAULT_LOCATION_PER_PAGE = 100000

export interface IPaging {
  page: number
  pageSize: number
}

export const getPage = (query: ParsedUrlQuery) => {
  if (!query.page) {
    return DEFAULT_PAGE
  }
  const page = parseInt(query.page as string, 10)
  return !Number.isNaN(page) && page > 0 ? page : DEFAULT_PAGE
}
export const getPageSize = (query: ParsedUrlQuery) => {
  if (!query.perPage) {
    return DEFAULT_PAGE_SIZE
  }
  const pageSize = parseInt(query.perPage as string, 10)
  return ALLOWED_PAGE_SIZES.includes(pageSize) ? pageSize : DEFAULT_PAGE_SIZE
}

export const usePaging = () => {
  const { pathname, query, push } = useRouter()

  const page = useMemo(() => getPage(query), [query.page])
  const pageSize = useMemo(() => getPageSize(query), [query.perPage])

  return {
    page,
    pageSize,
    setPage: (newPage: number) => {
      if (page === newPage) {
        return
      }
      push({
        pathname,
        query: omit(newPage === 1 ? 'page' : '')({
          ...query,
          page: newPage,
        }),
      })
    },
    setPageSize: (newPageSize: number) => {
      if (newPageSize === pageSize) {
        return
      }
      push({
        pathname,
        query: pipe(
          set('perPage', newPageSize),
          omit(['page', newPageSize === DEFAULT_PAGE_SIZE ? 'perPage' : ''])
        )(query) as any,
      })
    },
  }
}
